var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('h1',[_vm._v("Hackathon Submissions")])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('el-tooltip',{attrs:{"content":"refresh","placement":"top"}},[_c('span',{staticClass:"clickable"},[_c('i',{staticClass:"fas fa-redo",class:_vm.$apollo.loading ? 'fa-spin' : '',on:{"click":function($event){_vm.$apollo.queries.get_hackathon_judging_submissions.skip = false;
                  _vm.$apollo.queries.get_hackathon_judging_submissions.refetch();}}})])])],1)],1),(_vm.$apollo.loading && _vm.table_data.length == 0)?_c('div',[_c('b-row',{staticClass:"mt-2 d-flex align-items-center justify-content-center"},[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center"},[_c('Transition',[_c('h1',[_c('i',{staticClass:"fas fa-spinner fa-spin ml-1"})])])],1)],1)],1):_c('b-row',[_c('b-col',[_c('el-table',{staticClass:"table-responsive align-items-center table-flush no-lines",attrs:{"header-row-class-name":"thead-light","row-class-name":"table-rows","data":_vm.table_data,"empty-text":("" + (_vm.hackathon.is_creator
                ? 'No teams have submitted their work.'
                : 'Teams are busy working and will submit their work soon...'))}},[_c('el-table-column',{attrs:{"label":"Name","min-width":"150px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('p',{staticClass:"mb-0"},[_c('router-link',{attrs:{"to":{
                      name: 'HackathonJudgeSubmission',
                      params: {
                        hackathon_id: _vm.hackathon_id,
                        submission_id: row.id,
                      },
                    }}},[_vm._v(" "+_vm._s(row.team.name)+" ")])],1)]}}])}),(_vm.hackathon.is_judge)?_c('el-table-column',{attrs:{"label":"My Score","min-width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
return [_vm._v(" "+_vm._s(row.my_score)+"/"+_vm._s(row.max_score)+" ")]}}],null,false,2696176761)}):_vm._e(),(_vm.hackathon.is_judge)?_c('el-table-column',{attrs:{"min-width":"90px","label":"Scored"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
return _c('div',{staticClass:"table-actions"},[_c('el-tooltip',{attrs:{"content":row.judging_status
                      ? "Judging is finalised"
                      : "Please click on the 'Complete' button inside the team juding page to finalise your jugding.","placement":"top"}},[_c('b-badge',{attrs:{"pill":"","variant":row.judging_status ? 'info' : 'primary'}},[_vm._v(_vm._s(row.judging_status ? "Done" : "To Judge"))])],1)],1)}}],null,false,2676582988)}):_vm._e(),(_vm.hackathon.is_creator || _vm.hackathon.is_manager)?_c('el-table-column',{attrs:{"label":"Team Score","min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var row = ref.row;
return [_vm._v(" "+_vm._s(row.average_score)+"/"+_vm._s(row.max_score)+" ")]}}],null,false,1355441646)}):_vm._e(),_c('el-table-column',{attrs:{"label":"Judging Progress","min-width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var row = ref.row;
return [_vm._v(" "+_vm._s(row.judging_progress)+"/"+_vm._s(row.total_judges)+" completed ")]}}])}),(_vm.hackathon.is_creator || _vm.hackathon.is_manager)?_c('el-table-column',{attrs:{"min-width":"90px","label":"Status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var row = ref.row;
return _c('div',{staticClass:"table-actions"},[_c('el-tooltip',{attrs:{"content":row.judging_status
                      ? "Judging is finalised"
                      : "Please click on the 'Complete' button inside the team juding page to finalise your jugding.","placement":"top"}},[_c('b-badge',{attrs:{"pill":"","variant":row.judging_status ? 'info' : 'primary'}},[_vm._v(_vm._s(row.judging_status ? "Judging Complete" : "In Progress"))])],1)],1)}}],null,false,3643810561)}):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }